import React, { useEffect } from 'react';
import propTypes from 'prop-types';

const RedirectToCareers = ({ pageContext }) => {
  const { redirectUrl } = pageContext;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.href = redirectUrl;
    }
  }, []);

  return <div />;
};

RedirectToCareers.propTypes = {
  pageContext: propTypes.object,
};

export default RedirectToCareers;
